<template>
  <div>
    <b-container fluid class="p-0 mt-0">
      <b-row>
        <b-col cols="3"/>
        <b-col cols="6" style="font-size: 14px">
          <SubHeader>
            <b-row class="mt-2 mb-1">
              <b-col cols="4">
                <b-form-input v-model="search" placeholder="Suche nach Software..." size="sm" debounce="500"/>
              </b-col>
              <b-col class="ml-auto mt-2" cols="auto">
                <span class="text-secondary">ungefähre Installationszeit in HH:mm:ss</span>
              </b-col>
            </b-row>
          </SubHeader>
          <b-list-group :class="listGroupClasses">
            <b-list-group-item v-for="entry in softwareList" :key="entry.id" class="list-row">
              <b-row>
                <b-col cols="7">
                  <div>{{ entry.displayname }}</div>
                </b-col>
                <b-col v-if="entry.estimate_install_time" class="ml-auto" cols="auto">
                  <span class="text-secondary">{{ entry.estimate_install_time }}</span>
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col cols="3"/>
      </b-row>
    </b-container>
    <ScrollToTop/>
  </div>
</template>

<script>
import _ from "lodash";
import {mapActions, mapGetters} from "vuex";
import ScrollToTop from "@/components/utility/ScrollToTop";
import SubHeader from "@/components/structure/SubHeader";

export default {
  name: "SoftwareList",
  components: {
    SubHeader,
    ScrollToTop,
  },
  data () {
    return {
      search: '',
    }
  },
  methods: {
    ...mapActions(['resetSoftware']),
    ...mapActions(['getSoftwareList']),
    ...mapActions(['setLoadingState'])
  },
  computed: {
    ...mapGetters(['software']),
    ...mapGetters(['softwareCount']),
    ...mapGetters(['userThemeLight']),
    listGroupClasses() {
      if(this.userThemeLight) {
        return ['mt-2']
      }
      return ['text-white', 'mt-2']
    },
    softwareList() {
      return _.filter(this.software, (entry) => {
        if (entry.displayname.toLowerCase().includes(this.search.toLowerCase())) {return true}
      })
    }
  },
  beforeMount() {
    this.resetSoftware();
  },
  async mounted() {
    this.setLoadingState(true);
    await this.getSoftwareList();
    this.setLoadingState(false);
  }
}
</script>

<style lang="scss" scoped>
</style>
